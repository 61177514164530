#article-gallery {
  max-width: 1440px;
  margin: 0 auto;
  position: relative;
  justify-self: center;
}

#article-gallery h2 {
  padding-left: 20px;
}

#articles-wrapper {
  display: grid;
  position: relative;
  grid-template-columns: repeat(3, 1fr);
  gap: 2.2rem 2.2rem;
  padding: 36px;
  margin: 0 auto;
}

@media screen and (max-width: 769px) {
  #articles-wrapper {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media screen and (max-width: 575px) {
  #articles-wrapper {
    grid-template-columns: repeat(1, 1fr);
  }
}
