#articles-body-wrapper {
  flex-grow: 1;
}

.top-header-wrapper {
  padding-top: var(--nav-bar-height);
  background-color: rgb(39, 39, 39);
}

#article-list-header {
  text-align: center;
  padding-top: 20px;
}

#genre-body-wrapper {
  flex-grow: 1;
}

#genre-description {
  line-height: 1.8rem;
  font-size: 1.2rem;
  max-width: 800px;
}

#genre-description-wrapper {
  text-align: center;
}

@media screen and (max-width: 425px) {
  #genre-description {
    font-size: 1rem;
  }
}
