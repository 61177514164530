footer {
  display: grid;
  grid-template-rows: repeat(3, auto);
  grid-template-columns:
    minmax(10vw, 1fr) repeat(24, minmax(0, var(--cell-max-width)))
    minmax(10vw, 1fr);
  gap: 20px;
  background-color: rgb(39, 39, 39);
  min-height: 100px;
  justify-content: start;
  padding-bottom: 10px;
}

.footer-div {
  display: flex;
  flex-direction: column;
  row-gap: 10px;
}

.footer-div h5 {
  color: white;
  font-size: 1.2rem;
}

.footer-link {
  display: inline-block;
  position: relative;
  color: #8d8d8d;
  text-decoration: none;
  transition:
    color 0.6s cubic-bezier(0.19, 1, 0.22, 1),
    background-size calc(0.3s) cubic-bezier(0.19, 1, 0.22, 1);
}

.footer-link:hover {
  color: white;
}

.footer-link::after {
  content: "";
  background-color: #8d8d8d;
  position: absolute;
  bottom: -2px;
  left: 0;
  width: 100%;
  height: 1px;
  transition:
    color 0.6s cubic-bezier(0.19, 1, 0.22, 1),
    background-size calc(0.3s) cubic-bezier(0.19, 1, 0.22, 1);
}

.footer-link:hover::after {
  background-color: white;
}

#footer-socials {
  grid-area: 2 / 2 / 4 / 6;
}

#quicklinks {
  grid-area: 2 / 8 / 4 / 14;
}

#quicklinks li {
  list-style: none;
  margin-bottom: 5px;
}

.tweak-global-animations-animation-type-flex .sqs-block p a {
  display: inline;
  text-decoration: none;
  background-image: linear-gradient(currentColor, currentColor);
  background-repeat: no-repeat;
  background-size: 100% 1px;
  background-position: left bottom;
}
