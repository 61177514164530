#sub-gallery-banner {
  display: flex;
  justify-content: center;
  background-color: rgba(140, 140, 140, 1);
  padding: 10px 0;
}

#sub-gallery-banner div {
  display: flex;
  justify-content: flex-start;
  width: var(--independence-max-width, 1440px);
}

#sub-gallery-title {
  padding-left: min(8vw, 50px);
  color: white;
}

@media screen and (max-width: 425px) {
  #sub-gallery-title {
    font-size: 1.2rem;
    padding-left: 20px;
  }
}
