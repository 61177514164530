#article-body p {
  font-size: 1.2rem;
  line-height: 1.8;
  margin-bottom: 20px;
}

#article-body a {
  color: black;
}

#article-body a:hover {
  color: #404040;
}

.article-blockquote {
  text-align: center;
}
