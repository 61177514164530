#operations-team-top-header {
  margin-bottom: 3rem;
  text-align: center;
  font-size: calc(3rem + 1vw);
}

#operations-team-wrapper {
  width: 100vw;
  max-width: var(--independence-max-width, 1440px);
  padding: 0 24px;
  margin: 0 auto;
}

#operations-team-wrapper .swiper {
  width: 100%;
  padding-bottom: 20px;
}

#operations-team-wrapper .swiper-slide {
  max-width: 400px;
  display: flex;
  flex-direction: column;
  gap: 2rem;
  height: 100%;
  background-color: rgb(220, 220, 220);
  padding: 20px;
  padding-bottom: 5vh;
}

.operations-image {
  width: 100%;
  height: 100%;
  aspect-ratio: 1 / 1;
  border: 20px solid rgb(240, 240, 240);
  object-fit: cover;
  filter: grayscale(1);
}

.operations-card-details {
  text-align: center;
}

.operations-position {
  text-transform: uppercase;
  margin-top: 40px;
  font-weight: 400;
  font-size: 1rem;
  font-family: sans-serif;
}
