* {
  box-sizing: border-box;
}

#expanded-nav {
  position: fixed;
  z-index: 10;
  background-color: #fff;
  color: black;
  left: 0;
  top: 0;
  width: 100%;
  transition: opacity 0.3s ease-in-out;
  transform-origin: top;
  padding-bottom: 30px;
}

#expanded-nav.inactive {
  opacity: 0;
  pointer-events: none;
}

#expanded-nav.active {
  display: block;
  opacity: 1;
}

#expanded-menu-wrapper {
  display: flex;
  flex-direction: column;
  padding: 20px 0 0 20px;
}

#menu-close {
  margin-bottom: 20px;
  font-weight: 400;
  font-size: 1.2rem;
  display: inline-block;
  order: -2;
}

#expanded-nav p:hover {
  color: rgba(0, 0, 0, 0.5);
}

ul {
  padding: 0;
}

#categories li {
  padding: 0;
  list-style: none;
  position: relative;
}

#categories li a {
  text-decoration: none;
}

.category {
  font-family: sans-serif;
  margin: 0;
  font-weight: 600;
  text-decoration: none;
}

#expand-menu-mobile {
  display: none;
}

@media screen and (max-width: 767px) {
  #expand-menu-mobile {
    display: inline-block;
    order: -1;
  }

  #expand-menu-mobile ul {
    display: flex;
    column-gap: 20px;
    justify-content: space-evenly;
    list-style: none;
    padding: 10px;
    margin: 0 20px 20px 0;
    background: rgb(232, 232, 232);
    border-radius: 10px;
    width: auto;
  }

  #expand-menu-mobile ul a {
    color: black;
    font-size: 1.2rem;
  }
}
