* {
  box-sizing: border-box;
  margin: 0;
}

#main-nav {
  position: fixed;
  display: grid;
  grid-template: 1fr / 1fr auto 1fr;
  width: 100vw;
  height: var(--nav-bar-height, 80px);
  top: 0;
  z-index: 5;
  align-items: center;
  justify-content: space-between;
  background-color: rgb(39, 39, 39);
}

#expand-menu {
  display: flex;
  column-gap: 10px;
  align-items: center;
  justify-content: center;
}

#not-menu-expanded {
  display: flex;
  column-gap: 10px;
  align-items: center;
  justify-content: center;
}

#social-media-wrapper {
  display: flex;
  position: relative;
  height: 30px;
  place-items: flex-end;
}

#social-media-wrapper svg {
  width: 20px;
  height: 20px;
  fill: #fff;
  align-self: end;
  bottom: -3px;
}

#insta-logo:hover {
  color: red;
}

#independence-header {
  text-wrap: nowrap;
  justify-self: center;
  font-size: 2.2rem;
  font-weight: 400;
  font-family: Times, Georgia, serif;
  color: white;
  text-decoration: none;
}

#independence-header h4 {
  font-weight: 200;
}

#navbar {
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
}

#navbar li {
  list-style: none;
  padding: 0 20px;
  position: relative;
}

#navbar li a {
  text-decoration: none;
  font-size: 1.3rem;
  font-weight: 600;
  color: white;
  transition: 0.3s ease-in-out;
}

#mobile {
  display: none;
}

@media screen and (max-width: 768px) {
  #main-nav {
    grid-template-columns: 1fr auto 1fr;
    padding: 0 20px;
  }

  #navbar.active {
    display: flex;
    z-index: 10;
  }

  #mobile {
    display: flex;
    grid-area: 1 / 3 / 1 / 4;
    justify-content: flex-end;
    padding-right: 30px;
  }

  #mobile i {
    color: white;
    font-size: 1.5rem;
  }

  #shadow-overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background: rgba(0, 0, 0, 0.7);
    display: none;
  }

  #shadow-overlay.active {
    display: block;
    z-index: 5;
  }
}

@media screen and (max-width: 600px) {
  #independence-header h4 {
    font-size: calc(1rem + 2vw);
  }

  #expand-menu {
    grid-gap: 5px;
  }

  #not-menu-expanded {
    column-gap: 5px;
  }

  .nav-link {
    font-size: calc(0.8rem + 1vw);
  }
}

@media screen and (max-width: 425px) {
  #independence-header {
    text-wrap: nowrap;
  }

  #not-menu-expanded {
    display: none;
  }

  #logo-container {
    width: 21vw;
    height: 21vw;
  }
}
