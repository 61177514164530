#title-image-main {
  display: block;
}

#title-image {
  width: 100%;
  height: 100%;
  inset: 0;
  object-fit: cover;
  aspect-ratio: 16 / 9;
}

#title-image-wrapper > div {
  position: relative;
  grid-area: main-tl / main-tl / main-br / main-br;
}

#title-text-wrapper {
  color: white;
  z-index: 2;
  grid-area: cc;
  padding: 0;
  max-width: 50vw;
  text-align: center;
  justify-self: center;
}

#title-text-wrapper::before {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  inset: 0;
  background-color: rgba(0, 0, 0, 0.3);
  z-index: -1;
}

#subtitle {
  margin-top: 20px;
  font-size: 1.3rem;
  font-weight: 500;
}

.attribution {
  color: grey;
  font-size: 0.8rem;
  padding-left: 20px;
}

@media screen and (min-width: 768px) {
  #title-image-wrapper {
    display: grid;
    width: 100vw;
    height: 100vh;
    max-width: none;
    grid-template:
      "main-tl main-tc main-tr" 0.5fr
      "main-cl main-cc main-cr" 1fr
      "main-bl main-bc main-br" 0.5fr
      "attribution attribution attribution" / 0.5fr 1fr 0.5fr;
    box-sizing: border-box;
    position: relative;
  }

  #title-image-main {
    position: absolute;
    inset: 0;
    display: grid;
    height: 100%;
    max-height: 100vh;
    grid-template-areas:
      "tl tc tr"
      "cl cc cr"
      "bl bc br";
    padding: 20px 60px;
  }

  #title-image {
    position: absolute;
  }
}

@media screen and (max-width: 767px) {
  #title-text-wrapper {
    color: black;
    max-width: 100vw;
    padding: 20px;
    text-align: left;
  }

  #title-text {
    font-size: 1.5rem;
    padding-bottom: 10px;
  }

  #subtitle {
    font-size: 1rem;
  }

  #title-text-wrapper::before {
    display: none;
  }
}
