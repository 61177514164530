* {
  box-sizing: border-box;
}

#gallery-wrapper {
  background-color: rgb(39, 39, 39);
  padding-top: var(--nav-bar-height, 130px);
  padding-bottom: 20px;
  box-sizing: content-box;
  height: 576px;
  width: 100%;
  position: relative;
  min-height: 50vh;
}

.gradient-overlay {
  position: absolute;
  display: block;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background: linear-gradient(
    to bottom,
    rgba(0, 0, 0, 0.5),
    rgba(0, 0, 0, 0.2)
  );
  z-index: 1;
  /* Allows interactions with elements beneath the overlay */
}

.gallery-carousel {
  position: relative;
  max-width: 1440px;
  height: 100%;
  margin: 0 auto;
  border-radius: 5px;
}

.gallery {
  display: grid;
  height: 100%;
  grid-template:
    "main-tl main-tc main-tr" 0.5fr
    "main-cl main-cc main-cr" 1fr
    "main-bl main-bc main-br" 0.5fr
    "attribution attribution attribution" auto / 1fr 0.1fr 1fr;
  max-width: 100%;
  max-height: 100%;
  min-height: 17rem;
}

.image-header {
  font-size: 3.4rem;
  font-weight: 400;
  font-family: "Academica Book Pro", Times, Georgia, serif;
  text-decoration: none;
  color: white;
}

.image-text {
  display: flex;
  flex-direction: column;
  padding: 10px;
  align-items: flex-end;
  justify-content: flex-end;
  color: white;
  z-index: 10;
  text-align: center;
  /* Add a shadow */
}

.gallery-article-title {
  font-size: calc(1rem + 1vw);
}

.gallery-image {
  display: block;
  width: 100%;
  height: 100%;
  position: absolute;
  object-position: 50% 50%;
  object-fit: cover;
}

#gallery-image-wrapper {
  height: 100%;
  display: grid;
  grid-template:
    "main-tl main-tc main-tr" 1fr
    "main-cl main-cc main-cr" 0.5fr
    "main-bl main-bc main-br" 1fr / 1fr 0.75fr 1fr;
  align-items: center;
}

@keyframes revealFromLeft {
  from {
    animation-timing-function: cubic-bezier(0.2, 0.2, 0.25, 1);
    width: 0;
  }

  to {
    width: 100%;
  }
}

@keyframes hideFromRight {
  from {
    animation-timing-function: cubic-bezier(0.2, 0.2, 0.25, 1);
    width: 100%;
  }

  to {
    width: 0%;
  }
}

@media screen and (max-width: 425px) {
  #gallery-wrapper {
    height: 384px;
  }

  #gallery-image-wrapper {
    position: relative;
    height: 100%;
    display: flex;
    flex-direction: column;
  }

  .image-text {
    display: block;
    position: relative;
    z-index: 10;
    width: 100%;
  }

  .gallery-article-title {
    font-size: 1rem;
  }
}
