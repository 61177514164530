#root {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}

:root {
  --art: 59, 89, 193;
  --art-medium: 186, 197, 234;
  --art-light: 215, 220, 238;
  --diaspora: 2, 48, 71;
  --diaspora-medium: 193, 211, 228;
  --diaspora-light: 218, 229, 241;
  --politics: 235, 80, 7;
  --politics-medium: 232, 202, 188;
  --politics-light: 242, 229, 223;
  --science: 121, 52, 59;
  --science-medium: 219, 177, 181;
  --science-light: 239, 217, 219;
  --sports: 45, 106, 79;
  --sports-medium: 194, 229, 214;
  --sports-light: 232, 252, 242;
  --subheading-bg-color: rgba(140, 140, 140, 1);
  --nav-bar-height: 80px;
  --heading-1-size: 30px;
  --heading-1-size-value: 5.3;
  --heading-2-size: 26px;
  --heading-2-size-value: 3.4;
  --heading-3-size: 22px;
  --heading-3-size-value: 2.5;
  --heading-4-size: 18rem;
  --heading-4-size-value: 2;
  --independence-max-width: 1440px;
  --cell-max-width: calc(
    (var(--independence-max-width, 1440px) - (11px * 23)) / 24
  );
}

.art {
  color: rgb(var(--art));
}

.art-med-bg {
  background-color: rgb(var(--art-medium));
}

.art-light-bg {
  background-color: rgb(var(--art-light));
}

.diaspora {
  color: rgb(var(--diaspora));
}

.diaspora-med-bg {
  background-color: rgb(var(--diaspora-medium));
}

.diaspora-light-bg {
  background-color: rgb(var(--diaspora-light));
}

.politics {
  color: rgb(var(--politics));
}

.politics-med-bg {
  background-color: rgb(var(--politics-medium));
}

.politics-light-bg {
  background-color: rgb(var(--politics-light));
}

.science {
  color: rgb(var(--science));
}

.science-med-bg {
  background-color: rgb(var(--science-medium));
}

.science-light-bg {
  background-color: rgb(var(--science-light));
}

.sports {
  color: rgb(var(--sports));
}

.sports-med-bg {
  background-color: rgb(var(--sports-medium));
}

.sports-light-bg {
  background-color: rgb(var(--sports-light));
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.page-wrapper {
  width: 100vw;
  max-width: 1440px;
  margin: 0 auto;
  padding: 30px 24px;
}

/*** END NAVBAR STYLES ***/

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}
