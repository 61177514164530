@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  :root {
    --background: 0 0% 100%;
    --foreground: 222.2 47.4% 11.2%;
    --muted: 210 40% 96.1%;
    --muted-foreground: 215.4 16.3% 46.9%;
    --popover: 0 0% 100%;
    --popover-foreground: 222.2 47.4% 11.2%;
    --border: 214.3 31.8% 91.4%;
    --input: 214.3 31.8% 91.4%;
    --card: 0 0% 100%;
    --card-foreground: 222.2 47.4% 11.2%;
    --primary: 222.2 47.4% 11.2%;
    --primary-foreground: 210 40% 98%;
    --secondary: 210 40% 96.1%;
    --secondary-foreground: 222.2 47.4% 11.2%;
    --accent: 210 40% 96.1%;
    --accent-foreground: 222.2 47.4% 11.2%;
    --destructive: 0 100% 50%;
    --destructive-foreground: 210 40% 98%;
    --ring: 215 20.2% 65.1%;
    --radius: 0.5rem;
  }

  .dark {
    --background: 224 71% 4%;
    --foreground: 213 31% 91%;
    --muted: 223 47% 11%;
    --muted-foreground: 215.4 16.3% 56.9%;
    --accent: 216 34% 17%;
    --accent-foreground: 210 40% 98%;
    --popover: 224 71% 4%;
    --popover-foreground: 215 20.2% 65.1%;
    --border: 216 34% 17%;
    --input: 216 34% 17%;
    --card: 224 71% 4%;
    --card-foreground: 213 31% 91%;
    --primary: 210 40% 98%;
    --primary-foreground: 222.2 47.4% 1.2%;
    --secondary: 222.2 47.4% 11.2%;
    --secondary-foreground: 210 40% 98%;
    --destructive: 0 63% 31%;
    --destructive-foreground: 210 40% 98%;
    --ring: 216 34% 17%;
  }
}

@layer base {
  * {
    @apply border-border;
  }
  body {
    @apply font-sans antialiased bg-background text-foreground;
  }
}

* {
  box-sizing: border-box;
  margin: 0;
}

body,
html {
  height: 100%;
  width: 100%;
  background-color: rgb(240 240 240);
}

body {
  margin: 0;
  line-height: 1.4;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: Times, Georgia, serif;
}

/* HEADER */
h1 {
  font-size: min(calc(var(--heading-1-size) + 1vw), 2.8rem);
}

h2 {
  font-size: min(calc(var(--heading-2-size) + 1vw), 2.5rem);
}

h3 {
  font-size: min(calc(var(--heading-3-size) + 0.3vw), 2.2rem);
}

h4 {
  font-size: min(calc(var(--heading-4-size) + 0.5vw), 1.9rem);
}

/* NAVBAR */
.underlinable {
  position: relative;
}

.underlinable::after {
  content: "";
  position: absolute;
  transform: scaleX(0);
  width: 100%;
  height: 1px;
  bottom: -4px;
  left: 0;
  background-color: white;
  transform-origin: bottom right;
  transition: transform 0.25s ease-in-out;
}

.underlinable:hover::after {
  transform: scaleX(1);
  transform-origin: bottom left;
}

.nav-link {
  text-decoration: none;
  font-size: 1.3rem;
  font-weight: 600;
  color: white;
  transition: 0.3s ease-in-out;
  cursor: pointer;
}
.nav-separator {
  color: white;
}
