.article-card-wrapper {
  display: flex;
  flex-direction: column;
  row-gap: 10px;
  height: 100%;
  width: 100%;
  background-color: #e8e8e8;
  padding: 10px;
  border-radius: 5px;
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2);
}

.article-card-wrapper::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgb(240, 240, 240);
  animation: hideFromTop 0.5s forwards;
}

.article-card-link {
  color: black;
  text-decoration: none;
  position: relative;
}

.article-card-image {
  position: relative;
  overflow: hidden;
  border-radius: 5px;
  aspect-ratio: 16 / 9;
}

.article-card-image::after {
  content: "";
  display: block;
  position: absolute;
  background-color: transparent;
  top: 0;
  right: 0;
  width: 100%;
  height: 100%;
  transition: background-color 0.3s;
}

.article-card-image img {
  width: 100%; /* Scale images down to fit their containers */
  height: 100%; /* Maintain aspect ratio */
  display: block;
  transition: all 0.3s ease-in-out;
  filter: grayscale(1);
}

.article-card-image:hover img {
  transform: scale(1.05);
}

.article-banner {
  font-weight: 300;
  font-family: monospace;
}

.author {
  font-weight: 500;
  margin-top: auto;
}

.article-card-image.hover-highlight::after {
  content: "";
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  box-sizing: content-box;
  overflow: hidden;
}

.article-card-image.hover-highlight.art::after {
  background-color: rgba(var(--art), 0.4);
}

.article-card-image.hover-highlight.diaspora::after {
  background-color: rgba(var(--diaspora), 0.4);
}

.article-card-image.hover-highlight.politics::after {
  background-color: rgba(var(--politics), 0.4);
}

.article-card-image.hover-highlight.science::after {
  background-color: rgba(var(--science), 0.4);
}

.article-card-image.hover-highlight.sports::after {
  background-color: rgba(var(--sports), 0.4);
}

.article-title {
  font-family: "Academia Book Pro";
  font-size: 1.6rem;
  font-weight: 700;
  animation: fadeInFromBottom 0.9s forwards;
}

@keyframes hideFromTop {
  from {
    height: 100%;
    animation-timing-function: cubic-bezier(0.74, 0.23, 0.27, 1);
  }

  to {
    height: 0%;
    display: hidden;
  }
}

@keyframes fadeInFromBottom {
  from {
    animation-timing-function: ease-in;
    opacity: 0;
    bottom: -100;
  }

  to {
    opacity: 1;
    bottom: 0;
  }
}
