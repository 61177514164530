#profile-wrapper {
  display: flex;
  flex-wrap: wrap;
  max-width: 1280px;
  margin-top: 20px;
  margin-bottom: 40px;
  padding-top: 20px;
  column-gap: 10%;
  justify-content: space-between;
  align-items: center;
}

#image-container {
  flex: 1;
  max-width: 450px;
  min-width: 200px;
}

#profile-wrapper img {
  width: 100%;
  height: auto;
  object-fit: cover;
  border: 20px solid #e5e5e5;
  aspect-ratio: 1 / 1;
  filter: grayscale(1);
}

#author-details {
  flex-shrink: 0;
  flex-basis: auto;
  width: 100%;
  max-width: 600px;
}

#author-details p {
  text-align: left;
  font-size: calc(0.8rem + 0.3vw);
  font-weight: 400;
  width: 100%;
  height: auto;
  max-width: 100%;
  line-height: 1.8rem;
}

#author-details h2 {
  font-weight: 700;
  margin-bottom: 30px;
}

@media screen and (max-width: 950px) {
  #profile-wrapper {
    display: block;
  }

  #profile-wrapper img {
    border: 10px solid #e5e5e5;
  }

  #image-container {
    float: left;
    padding: 0 20px 20px 0;
    width: auto;
    max-width: 200px;
    min-width: 0;
  }

  #author-details {
    max-width: 100%;
  }

  #author-details h2 {
    margin-bottom: 0;
  }
}

@media screen and (max-width: 425px) {
  #author-details h2 {
    font-size: 1.2rem;
  }

  #author-details p {
    font-size: 0.75rem;
    line-height: 1.2rem;
  }
}

@media screen and (max-width: 400px) {
  #image-container {
    width: 160px;
    float: none;
  }
}
